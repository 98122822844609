<template>
  <v-card class="PlanejamentoAcaoFormCampos__Card--treslinhas">
    <v-form
      ref="formApuracao"
      lazy-validation
      autocomplete="off">
      <v-card-title>
        <div class="card-title">
          {{ $t('label.base_calculo') }}
        </div>
      </v-card-title>
      <v-card-text>
        <v-container fluid grid-list-md class="pa-0">
          <v-row dense>
            <v-col cols="12">
              <v-radio-group
                id="plan_acao_base_calculo"
                v-if="!isModalidadeSellout"
                v-model="formulario.baseCalculo"
                :label="`${$tc('label.base_calculo',1)} *`"
                dense
                class="mt-0"
                @change="baseCalculoAlterada"
                :disabled="somenteLeitura"
                :rules="[rules.required]">
                <v-radio
                  v-for="(b, index) in listaBasesCalculoPermitidas"
                  :key="index"
                  class="my-0"
                  :label="b.nome"
                  :value="b.valor"></v-radio>
              </v-radio-group>
              <span>{{ `${$tc('title.desconto', 2)} *` }}</span>
              <v-checkbox
                :id="`plan_acao_desconto-${d.valor}`"
                v-for="(d, index) in listaDescontos"
                v-model="formulario.descontos"
                v-if="exibirDesconto(d)"
                class="my-0"
                dense
                hide-details
                :key="index"
                :disabled="somenteLeitura || (d === 'DEVOLUCOES' && !isModalidadeSellout)"
                :label="`${$t('label.' + d.toLowerCase())}`"
                :value="d">
              </v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
export default {
  props: {
    novaAcao: Boolean,
    somenteLeitura: Boolean,
    acao: Object,
    configuracao: {
      type: Object,
      required: true,
    },
  },
  components: {
  },
  watch: {
  },
  data() {
    return {
      formulario: {
        baseCalculo: null,
        descontos: [],
      },
      gravaDescontos: [],
      listaDescontos: [],
      listaBasesCalculoPermitidas: [],

      listaBasesCalculo: [
        {
          valor: 'VALOR_BRUTO',
          nome: this.$tc('label.valor_bruto'),
        },
        {
          valor: 'VALOR_LIQUIDO',
          nome: this.$tc('label.valor_liquido'),
        },
        {
          valor: 'NOTAS_DEVOLUCAO',
          nome: this.$tc('label.nota_devolucao', 2),
        },
      ],

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    isModalidadeSellout() {
      return this.acao.calculoBonificacao && this.acao.calculoBonificacao === 'SELLOUT';
    },
    basesCalculoPermitidas() {
      const { basesCalculo } = this.configuracao;
      if (this.isModalidadeSellout) {
        return this.listaBasesCalculo.filter((x) => x.valor === 'VALOR_BRUTO');
      }
      return this.listaBasesCalculo.filter((p) => basesCalculo.indexOf(p.valor) >= 0);
    },
  },
  methods: {
    baseCalculoAlterada() {
      if (this.formulario.baseCalculo === 'VALOR_BRUTO') {
        this.formulario.descontos = ['DEVOLUCOES'];
      }
      if (this.formulario.baseCalculo === 'VALOR_LIQUIDO') {
        this.formulario.descontos.push('ICMS', 'IPI', 'PIS_COFINS');
        alert(this.$t('message.alerta_base_calculo')); // eslint-disable-line
      }
    },
    exibirDesconto(desconto) {
      if (this.formulario.baseCalculo === 'VALOR_BRUTO' && desconto !== 'DEVOLUCOES') {
        return false;
      }
      return true;
    },
    getObjetoFormatado() {
      const objeto = { ...this.formulario };
      return objeto;
    },
    preencherAcaoNova() {
      if (this.listaBasesCalculoPermitidas.length === 1) {
        this.formulario.baseCalculo = this.listaBasesCalculoPermitidas[0].valor;
      }
    },
    valida() {
      return this.$refs.formApuracao.validate();
    },
    montaObjetoAcao() {
      this.formulario = {
        baseCalculo: this.acao.baseCalculo,
        descontos: this.acao.descontos,
      };
    },
    montarListaDescontos() {
      const { configuracao } = this;
      this.listaDescontos = configuracao.descontos.sort();
      if (this.novaAcao && this.listaDescontos.length === 1) {
        this.formulario.descontos.push(this.listaDescontos[0]);
      }
    },
  },
  mounted() {
    if (this.isModalidadeSellout) {
      this.listaBasesCalculoPermitidas = this.basesCalculoPermitidas.filter((x) => x.valor === 'VALOR_BRUTO');
    } else {
      this.formulario.descontos = ['DEVOLUCOES'];
      this.listaBasesCalculoPermitidas = this.basesCalculoPermitidas;
    }
    if (this.novaAcao) {
      this.preencherAcaoNova();
    } else {
      this.montaObjetoAcao();
    }
    this.montarListaDescontos();
  },
};
</script>
