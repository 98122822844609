<template>
  <v-dialog v-model="showModal" max-width="800">
    <v-form ref="form" lazy-validation autocomplete="off">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ title }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" v-if="passoDiretor != null">
                <v-select
                  id="select_aprovador_diretor"
                  name="select_aprovador_diretor"
                  item-text="nome"
                  item-value="id"
                  clearable
                  v-model="selecaoDiretor"
                  :rules="[rules.required]"
                  :items="passoDiretor.usuarios"
                  :label="`${$tc('label.diretor_categoria', 1)} *`">
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs" class="item-lista py-1">
                      <v-list-item-content>
                        <span>{{ item.nome }}</span>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" v-if="passoFornecedor != null">
                <v-select
                  id="select_aprovador_cliente"
                  name="select_aprovador_cliente"
                  item-text="nome"
                  item-value="id"
                  clearable
                  v-model="selecaoFornecedor"
                  :rules="[rules.required]"
                  :items="passoFornecedor.usuarios"
                  :label="`${$tc('label.fornecedor_aprovacao', 1)} *`">
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs" class="item-lista py-1">
                      <v-list-item-content>
                        <span>{{ item.nome }}</span>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="cancela">{{ $t('label.cancelar') }}</v-btn>
          <v-btn color="primary darken-1" text @click.native="confirma">{{ $t('label.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  name: 'PlanejamentoAcaoFormUsuarioAprovador',
  data() {
    return {
      showModal: false,
      selecaoFornecedor: undefined,
      selecaoDiretor: undefined,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  props: {
    passoFornecedor: Object,
    passoDiretor: Object,
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    open() {
      this.selecao = undefined;
      this.showModal = true;
    },
    cancela() {
      this.showModal = false;
    },
    confirma() {
      if (!this.$refs.form.validate()) return;
      const dadosCondicional = {
        passosFornecedor: [],
      };
      if (this.passoDiretor != null && this.passoDiretor.idPasso) {
        dadosCondicional.idPassoDiretor = this.passoDiretor.idPasso;
        dadosCondicional.idDiretor = this.selecaoDiretor;
      }
      if (this.passoFornecedor && this.passoFornecedor.idPasso) {
        const usuarios = [];
        const usuFormatado = {
          id: this.selecaoFornecedor,
        };
        usuarios.push(usuFormatado);
        const dadosFornecedor = {
          idPasso: this.passoFornecedor.idPasso,
          tipoFornecedor: this.passoFornecedor.tipoFornecedor,
          usuarios,
        };
        dadosCondicional.passosFornecedor.push(dadosFornecedor);
      }
      this.$emit('selecionarUsuarioAprovador', dadosCondicional);
      this.showModal = false;
    },
  },
  mounted() {
  },
};
</script>
<style lang="css">
.item-lista:hover{
   background-color: rgb(221, 220, 220);
   opacity: 0,5;
}
</style>
