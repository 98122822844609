<style>
  .PlanejamentoAcaoForm {
    margin-bottom: 70px;
    }
  .PlanejamentoAcaoForm .card-title {
    font-size: 18px;
    font-weight: 500;
  }
  .PlanejamentoAcaoForm .card-subtitle {
    font-size: 16px;
    font-weight: 400;
  }
</style>
<template>
  <div class="PlanejamentoAcaoForm" v-if="!!configuracao">

    <planejamento-acao-verba-variavel-form-campos
      ref="formCampos"
      v-if="carregouEstrutura"
      :tipo-acao="tipoAcao"
      :configuracao="configuracao"
      :acao-edicao="acaoEdicao"
      :copia-acao="copiaAcao"
      :is-copia-acao="copia"
      :nova-acao="novo"
      :somente-leitura="naoPodeEditar"
      @PlanejamentoAcaoForm__AtualizaIndTemEvidencia="atualizaIndTemEvidencia"
      @PlanejamentoAcaoForm__isValorPendenteZerado="setarIsValorPendenteZerado"
      @PlanejamentoAcaoForm__cancelar="cancelarEdicao">
    </planejamento-acao-verba-variavel-form-campos>

    <div class="PlanejamentoAcaoForm_Acoes" v-if="carregouEstrutura">
      <planejamento-acao-verba-variavel-form-rodape
        ref="rodapeAcoes"
        :nova-acao="novo"
        :somente-leitura="naoPodeEditar"
        :acao-edicao="acaoEdicao"
        :copia-acao="copiaAcao"
        :is-copia-acao="copia"
        :configuracao="configuracao"
        :valida-salvar="validaSalvar"
        :is-valor-pendente-zerado="isValorPendenteZerado"
        :get-objeto-formatado="getObjetoFormatado"
        :salvar-novo-budget-id="salvarNovoBudgetId"
        :ind-tem-evidencia="indTemEvidencia"
        />
    </div>
  </div>
</template>
<script>
import PlanejamentoAcaoVerbaVariavelFormCampos from './PlanejamentoAcaoVerbaVariavelFormCampos';
import PlanejamentoAcaoVerbaVariavelFormRodape from './PlanejamentoAcaoVerbaVariavelFormRodape';
import AcaoConfiguracao from '../AcaoConfiguracao';

export default {
  components: {
    PlanejamentoAcaoVerbaVariavelFormCampos,
    PlanejamentoAcaoVerbaVariavelFormRodape,
  },
  computed: {
    carregouEstrutura() {
      return this.tipoAcao != null && this.configuracao != null
        && (this.novo || (!this.novo && this.acaoEdicao.id));
    },
    naoPodeEditar() {
      return this.somenteLeitura
          || this.statusAcao !== 'EM_CADASTRO';
    },
  },
  props: {
    novo: Boolean,
    somenteLeitura: Boolean,
    copia: Boolean,
  },
  data() {
    return {
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),
      usuarioLogadoResources: this.$api.usuarioLogado(this.$resource),
      isValorPendenteZerado: false,
      configuracao: null,
      tipoAcao: null,
      acaoEdicao: {},
      statusAcao: 'EM_CADASTRO',
      copiaAcao: {},

      tiposVerbaUsuarioLogado: [],

      indTemEvidencia: false,
    };
  },
  methods: {
    cancelarEdicao(forceVoltaParaLista = false) {
      this.$refs.rodapeAcoes.cancelar(forceVoltaParaLista);
    },
    validaSalvar(isSolicitandoAprovacao) {
      return this.$refs.formCampos.valida(isSolicitandoAprovacao);
    },
    carregarAcao(idAcao) {
      this.idAcao = idAcao;
      this.planejamentoAcaoListagemResource.buscarAcao({ idAcao })
        .then((res) => {
          this.acaoEdicao = {
            ...res.data,
            ...res.data.passoGeral,
            ...res.data.passoComportamento,
            ...res.data.linhasProduto,
          };
          this.statusAcao = this.acaoEdicao.status;
          return this.acaoEdicao.idAcaoTipo;
        }).then((idAcaoTipo) => {
          Promise.all([
            this.carregarTipoAcao(idAcaoTipo),
            this.carregarConfiguracao(idAcaoTipo),
          ]).then(() => {
            setTimeout(() => this.$refs.rodapeAcoes.carregaInformacoesFluxo(idAcao));
          });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarAcaoCopiada(idAcao) {
      this.idAcao = idAcao;

      this.planejamentoAcaoListagemResource.buscarAcao({ idAcao })
        .then((res) => {
          this.acaoEdicao = {
            ...res.data,
            ...res.data.passoGeral,
            ...res.data.passoComportamento,
            ...res.data.linhasProduto,
          };
          this.acaoEdicao.status = this.statusAcao;
          return this.acaoEdicao.idAcaoTipo;
        }).then((idAcaoTipo) => {
          Promise.all([
            this.carregarTipoAcao(idAcaoTipo),
            this.carregarConfiguracao(idAcaoTipo),
          ]).then(() => {});
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarConfiguracao(idTipoAcao) {
      const { idAcao } = this;

      const promise = this.novo
        ? this.configuracaoResource.buscarConfigVigente({ idTipoAcao })
        : this.configuracaoResource.buscarConfigSnapshot({ idAcao });

      return promise.then((res) => {
        this.configuracao = new AcaoConfiguracao(res.data);
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarTipoAcao(idTipoAcao) {
      return this.tipoAcaoResource.buscarTipoAcaoComConfiguracao({ idTipoAcao })
        .then((res) => {
          this.tipoAcao = res.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    carregarSeUsuarioPossuirAcesso(idAcao) {
      this.planejamentoAcaoListagemResource.verificarSeUsuarioPossuiAcesso({ idAcao })
        .then((res) => {
          if (res.data) {
            this.carregarAcao(idAcao);
          } else {
            this.$router.push({ name: 'inicio' });
            this.$toast('Acesso negado');
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    setarIsValorPendenteZerado(isValorPendenteZerado) {
      this.isValorPendenteZerado = isValorPendenteZerado;
    },
    getObjetoFormatado() {
      return this.$refs.formCampos.getObjetoFormatado();
    },
    salvarNovoBudgetId() {
      return this.$refs.formCampos.salvarNovoBudgetId();
    },
    atualizaIndTemEvidencia(indicador) {
      this.indTemEvidencia = indicador;
    },
  },
  mounted() {
    if (this.$route.params.copy) {
      const idTipoAcao = this.$route.params.acaoCopiada.id_acao_tipo;
      const idAcao = this.$route.params.id;
      this.carregarAcaoCopiada(idAcao, idTipoAcao);
    } else if (!this.$route.params.idAcao && !this.$route.params.configuracao) {
      this.$router.push({ name: 'planejamento-acao' });
    } else if (this.novo && !this.copia) {
      const { idTipoAcao } = this.$route.params;
      Promise.all([
        this.carregarTipoAcao(idTipoAcao),
        this.carregarConfiguracao(idTipoAcao),
      ]).then(() => {
        setTimeout(() => this.$refs.formCampos.montarNovaAcao(this.$route.params));
      });
    } else {
      const { idAcao, from } = this.$route.params;
      if (!from) {
        this.carregarSeUsuarioPossuirAcesso(idAcao);
      } else {
        this.carregarAcao(idAcao);
      }
    }
  },
};
</script>
